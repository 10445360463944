import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/template/Layout'
import Cal from '@material-ui/icons/Today'
import { Container, Grid } from '@material-ui/core'

export default function Action({ data }) {
  const post = data.markdownRemark
  return (
    <Layout title={`Action: ${post.frontmatter.title}`} description={post.frontmatter.description} type="Action" image={post.frontmatter.thumbnail}>
      <Container>
        <Grid container>
          <Grid item><Cal/></Grid>
          <Grid item><time>{post.frontmatter.year}</time></Grid>
        </Grid>
        <h2>{post.frontmatter.description}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        thumbnail
        year(formatString: "YYYY", locale:"fr")
      }
    }
  }
`